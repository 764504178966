<template>
  <div id="products">
    <v-container
      fluid
      class="pl-0 pr-0"
    >
      <Header />
      <Alert
        :showAlert="isErrorMessage"
        v-if="isErrorMessage"
        type="error"
        class="mt-2"
        allowDismiss
        :text="errorMessage"
        :inPage="false"
        :backgroundColour="true"
      />
      <Alert
        :showAlert="isProductUpdated"
        type="success"
        allowDismiss
        absolute
        text="The changes have been saved successfully"
        :inPage="false"
        :backgroundColour="true"
      />
    </v-container>
    <v-container
      fluid
      class="d-flex flex-column px-2"
    >
      <AdsButton
        width="220"
        class="mb-2"
        :primary="true"
        buttonText="Add Supplier Product"
        icon="mdi-plus"
        @click="createNewProduct"
        :disabled="isReadOnlyUser"
      />
      <AdsDataTable
        :headers="headers"
        :items="items"
        :footer-props="{
          'items-per-page-options': [20, 30, 50, -1],
        }"
        :items-per-page="20"
        search-label="Find products"
        sort-desc
        @click:row="openProduct"
      >
        <template v-slot:item.isFinancial="{ item }">
          <div class="d-flex justify-center">
            {{ item.isFinancial }}
          </div>
        </template>
      </AdsDataTable>
    </v-container>
  </div>
</template>
<script>
import { AdsDataTable, AdsButton, Alert } from '@nswdoe/doe-ui-core'
import Header from '@/views/Products/Header/Header'

export default {
  name: 'ProductsListing',
  title: 'Products - SAIS Operation UI Portal',
  components: {
    AdsDataTable,
    AdsButton,
    Header,
    Alert,
  },
  data() {
    return {
      headers: [
        { text: 'Supplier', value: 'supplierName' },
        { text: 'Product Type', value: 'type' },
        { text: 'Product Code', value: 'productCode' },
        { text: 'Supplier Product', value: 'name' },
        { text: 'Finance Exclusive', value: 'isFinancial', align: 'center' },
        { text: 'Subcategories', value: 'services', sortable: false },
        { text: 'State', value: 'state' },
        { text: 'Product Id', value: 'productId' },
      ],
      items: [],
      itemsPerPageOptions: [
        { value: 20, title: '20' },
        { value: 30, title: '30' },
        { value: 50, title: '50' },
        { value: 100, title: '100' },
        { value: -1, title: '$vuetify.dataFooter.itemsPerPageAll' },
      ],
    }
  },
  created() {
    this.$store.dispatch('moduleProducts/fetchProducts').finally(() => {
      this.items =
        this.$store.state.moduleProducts.products?.map((product) => ({
          ...product,
          isFinancial: product.isFinancial ? 'Yes' : 'No',
          services: product.services.map((service) => service.name).join(' '),
        })) || []
    })
  },
  computed: {
    isProductUpdated() {
      return this.$route.query.created === 'true' || this.$route.query.updated === 'true'
    },
    isAdmin() {
      return this.$store.state.isAdmin
    },
    isReadOnlyUser() {
      return this.$store.state.isReadOnlyUser
    },
    errorMessage() {
      return this.$store.state.moduleProducts.errorMessage
    },
    isErrorMessage() {
      const errorMessage = this.$store.state.moduleProducts.errorMessage
      return !!errorMessage && errorMessage.length > 0
    },
  },

  methods: {
    openProduct(item) {
      this.$router.push(`/serviceregistry/products/view/${item.productId}/${item.supplierId}`)
    },
    createNewProduct() {
      this.$router.push('/serviceregistry/products/add')
    },
  },
}
</script>
